export default function (componentGetMethod) {
  return {
    watch: {
      '$route.query': {
        async handler(newQuery, oldQuery) {
          if (JSON.stringify(newQuery) !== JSON.stringify(oldQuery)) {
            this.initializeFromQuery()
            await componentGetMethod.call(this, { ...newQuery })
            setTimeout(() => {
              window.scrollTo(0, this.$store.getters.getScrollY)
              this.$store.commit('setScrollY', 0)
            }, 500)
          }
        },
        immediate: true,
      },
    },

    methods: {
      updatedQueryParams(query) {
        const cleanedQuery = Object.fromEntries(
          Object.entries(query).filter(
            ([, value]) =>
              value !== null && value !== undefined && value !== '' && value,
          ),
        )
        const currentQuery = this.$route.query
        if (JSON.stringify(currentQuery) !== JSON.stringify(cleanedQuery)) {
          this.$router.push({ query: { ...cleanedQuery } }).catch((err) => {
            if (err.name !== 'NavigationDuplicated') {
              console.error(err)
            }
          })
        }
      },
    },
  }
}
