<template>
  <section :class="$style.bonusProgram">
    <div :class="$style.wrapper">
      <adw-tabs
        :activeTab="activeBonusProgramsTab"
        :tabs="bonusProgramTabs"
        color="gold"
        :class="$style.tabs"
        @change="handleChangeTab"
      ></adw-tabs>
      <div v-if="!isRemoteManager" :class="$style.buttons">
        <adw-button
          color="primary"
          :link="
            getRoute({
              route: $options.ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.CREATE,
            })
          "
        >
          Создать бонусную программу
        </adw-button>
      </div>
    </div>
    <el-table :data="bonusPrograms" stripe>
      <el-table-column prop="name" label="Название"></el-table-column>
      <el-table-column prop="description" label="Описание"></el-table-column>
      <el-table-column prop="status" label="Статус"
        ><template slot-scope="scope">
          <span
            :class="[
              {
                [$style.active]:
                  scope.row.status === $options.BONUS_PROGRAM_STATUSES.ACTIVE,
              },
              {
                [$style.archive]:
                  scope.row.status === $options.BONUS_PROGRAM_STATUSES.ARCHIVED,
              },
            ]"
            >{{ getDisplayStatus(scope.row.status) }}</span
          >
        </template></el-table-column
      >
      <el-table-column prop="startsAt" label="Дата начала">
        <template slot-scope="scope">
          {{ formatDate(scope.row.startsAt) }}
        </template></el-table-column
      >
      <el-table-column prop="endsAt" label="Дата окончания">
        <template slot-scope="scope">
          {{ formatDate(scope.row.endsAt) }}
        </template></el-table-column
      >
      <el-table-column width="200">
        <template slot-scope="scope">
          <router-link
            v-if="scope.row.status !== $options.BONUS_PROGRAM_STATUSES.ARCHIVED"
            :to="
              getRoute({
                route:
                  $options.ADDSELLERS_ADMIN_ROUTES.BONUS_PROGRAM.EXCLUDE_DEALERS
                    .INDEX,
                params: { id: scope.row.id },
              })
            "
            :class="$style.link"
            >Исключенные дилеры</router-link
          >
        </template></el-table-column
      >
      <el-table-column width="120">
        <template slot-scope="scope">
          <ActionButtons
            :add-trash="false"
            :edit-link="
              isStatusInactive(scope.row.status)
                ? getViewLinkByStatus(scope.row.id, scope.row.status)
                : ''
            "
            :view-link="
              !isStatusInactive(scope.row.status)
                ? getViewLinkByStatus(scope.row.id, scope.row.status)
                : ''
            "
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalPages > 1"
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="handlePageChange"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'
import { getRoute, ADDSELLERS_ADMIN_ROUTES } from '@/constants/routing'
import { mapGetters } from 'vuex'
import {
  BONUS_PROGRAM_STATUSES_DISPLAY,
  BONUS_PROGRAM_STATUSES,
  BONUS_PROGRAM_ROUTES,
} from '@/constants/bonusProgram'
import { formatDate } from '@/helpers/index.js'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import addQueryParamsAddsellers from '@/mixins/addQueryParamsAddsellers'

export default {
  mixins: [
    addQueryParamsAddsellers(function (params) {
      this.getList(params)
    }),
  ],
  components: {
    ActionButtons,
  },
  ADDSELLERS_ADMIN_ROUTES,
  BONUS_PROGRAM_STATUSES_DISPLAY,
  BONUS_PROGRAM_STATUSES,
  BONUS_PROGRAM_ROUTES,
  data() {
    return {
      bonusPrograms: [],
      limit: 20,
      activeBonusProgramsTab: 'all',
      page: 1,
      total: 0,
      bonusProgramsTabs: [
        {
          text: 'Все',
          value: 'all',
        },
        {
          text: 'Активные',
          value: 'active',
        },
        {
          text: 'Архивные',
          value: 'archive',
        },
        {
          text: 'Не активные',
          value: 'inActive',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isRemoteManager: 'isRemoteManager',
    }),
    totalPages() {
      return Math.ceil(this.total / this.limit)
    },
    bonusProgramTabs() {
      return this.isRemoteManager
        ? [{ ...this.bonusProgramsTabs[1] }]
        : this.bonusProgramsTabs
    },
  },

  methods: {
    initializeFromQuery() {
      this.bonusPrograms = this.$route.query.bonusPrograms || []
      this.limit = this.$route.query.limit || 20
      this.page = parseInt(this.$route.query.page, 10) || 1
      this.orderBy = this.$route.query.orderBy || 'order_field'
      this.orderDir = this.$route.query.orderDir || 'desc'
    },

    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
        orderBy: 'starts_at',
        orderDir: 'desc',
      }

      if (this.activeBonusProgramsTab === 'inActive') {
        query.status = BONUS_PROGRAM_STATUSES.INACTIVE
      }

      if (this.activeBonusProgramsTab === 'archive') {
        query.status = BONUS_PROGRAM_STATUSES.ARCHIVED
      }

      if (this.activeBonusProgramsTab === 'active') {
        query.status = BONUS_PROGRAM_STATUSES.ACTIVE
      }

      const { value, error } =
        await delivery.AddwineCore.BonusProgramActions.getList(query)

      loading.close()

      if (error) return

      this.bonusPrograms = value.data
      this.total = value.meta.count
    },

    isStatusInactive(status) {
      return status === BONUS_PROGRAM_STATUSES.INACTIVE
    },
    getViewLinkByStatus(id, status) {
      const routeObj = {
        route: '',
        params: { id },
      }
      routeObj.route = BONUS_PROGRAM_ROUTES[status] ?? ''
      return this.getRoute(routeObj)
    },
    getRoute({ route, params }) {
      return getRoute({ route, params })
    },
    formatDate(date) {
      return formatDate(date)
    },
    getDisplayStatus(status) {
      return BONUS_PROGRAM_STATUSES_DISPLAY[status] ?? ''
    },
    async handleChangeTab(event) {
      const previusActiveBonusProgramsTab = this.activeBonusProgramsTab

      if (previusActiveBonusProgramsTab !== event) {
        this.page = 1
      }

      this.activeBonusProgramsTab = event

      if (previusActiveBonusProgramsTab !== this.activeBonusProgramsTab) {
        await this.updateURL()
        await this.getList()
      }
    },
    updateURL() {
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: this.activeBonusProgramsTab,
          page: this.page,
        },
      })
    },
    handlePageChange(newPage) {
      this.$router.push({
        query: {
          ...this.$route.query,
          page: newPage,
        },
      })

      this.page = newPage
      this.getList()
    },
  },
}
</script>

<style lang="scss" module>
.bonusProgram {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    justify-content: space-between;
    margin-bottom: 1rem;

    .tabs {
      & > li {
        @include H200;
        list-style: none;
      }
    }

    .buttons {
      display: flex;
      gap: 3rem;
    }
  }

  .active {
    color: $complite;
  }

  .archive {
    color: $gray;
  }
  .pagination {
    @include pagination;
  }

  .link {
    text-decoration: none;
  }
}
</style>
